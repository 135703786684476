let PopComponent = {};
PopComponent['AddPhysicianPop'] = require('../component/Popup/PhysicianPop/AddPhysicianPop').default;
PopComponent['AuthorizedPop'] = require('../component/Popup/ClinicDetailPop/AuthorizedPop').default;
PopComponent['DeleteAlert'] = require('../component/DeletePop').default;
PopComponent['EditPhysicianPop'] = require('../component/Popup/PhysicianPop/EditPhysicianPop').default;
PopComponent['editAuthorizedPop'] = require('../component/Popup/ClinicDetailPop/EditAuthorizedPop').default;
PopComponent['SwitchPrimaryPop'] = require('../component/Popup/ClinicDetailPop/SwitchPrimaryPop').default;
PopComponent['EditCinicDetailPop'] = require('../component/Popup/ClinicDetailPop/EditClinicDetailPop').default;
PopComponent['DeletePop'] = require('../component/Popup/ClinicPop/DeletePop').default;
PopComponent['TransferPatientsPop'] = require('../component/Popup/PhysicianPop/TransferPatientsPop').default;
PopComponent['AddMinutePop'] = require('../component/Popup/PhysicianPop/AddMinutePop').default;
PopComponent['PhysicianDetailsPop'] = require('../component/Popup/PhysicianPop/PhysicianDetailsPop').default;
PopComponent['DeleteAuthorizedPop'] = require('../component/Popup/ClinicDetailPop/DeleteAuthorizedPop').default;
PopComponent['DeletePhysicianPop'] = require('../component/Popup/PhysicianPop/DeletePhysicianPop').default;
PopComponent['PhyStatusUpdatePop'] = require('../component/Popup/PhysicianPop/PhyStatusUpdatePop').default;
PopComponent['ClinicStatusUpdatePop'] = require('../component/Popup/ClinicPop/ClinicStatusUpdatePop').default
PopComponent['AuthorizedUserStatusPop'] = require('../component/Popup/ClinicDetailPop/AuthorizedUserStatusPop').default
PopComponent['CommonPop'] = require('../component/Popup/CommonPop').default
PopComponent['ChangeMinutes'] = require('../component/Popup/ClinicPop/ChangeMinutes').default
PopComponent['EditClinicList'] = require('../component/Popup/ClinicPop/EditClinicList').default
PopComponent['ChangeMinutesPlanBtn'] = require('../component/Popup/ClinicDetailPop/ChangeMinutesPlanBtn').default
PopComponent['CancelMinutesPlanBtn'] = require('../component/Popup/ClinicDetailPop/CancelMinutesPlanBtn').default
PopComponent['EnablePhyStatusPop'] = require('../component/Popup/PhysicianPop/EnablePhyStatusPop').default

    // PopComponent['ChangeMinutesPlanBtn'] = require('../component/Popup/ClinicDetailPop/ChangeMinutesPlanBtn').default;
    // PopComponent['CancelMinutesPlanBtn'] = require('../component/Popup/ClinicDetailPop/CancelMinutesPlanBtn').default;

export default PopComponent