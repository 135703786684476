export const links = [
    {
        id: 1,
        name: "Clinics",
        route: "/clinics",
        role:["admin"]
    },
    {
        id: 2,
        name: "physicians",
        route: "/physicians",
        role:["admin","clinic"]
    },{
        id: 2,
        name: "website leads",
        route: "/website-leads",
        role:["admin","clinic"]
    }
];