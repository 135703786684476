export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//clinics
export const GET_CLINIC_LIST = "GET_CLINIC_LIST";
export const ADD_CLINIC_LIST = "ADD_CLINIC_LIST";
export const ADD_CLINIC = "ADD_CLINIC";
export const GET_CLINIC = "GET_CLINIC";
export const UPDATE_CLINIC = "UPDATE_CLINIC";
export const UPDATE_CLINIC_STATUS = "UPDATE_CLINIC_STATUS";
export const ADD_AUTHORIZED = "ADD_AUTHORIZED";
export const GET_CLINIC_DETAILS = "GET_CLINIC_DETAILS";
export const DELETE_AUTH_USER = "DELETE_AUTH_USER";
export const EDIT_AUTH_USER = "EDIT_AUTH_USER"
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const CHANGE_PRIMARY_AUTH_USER = "CHANGE_PRIMARY_AUTH_USER";
export const UPDATE_AUTH_USER_STATUS = "UPDATE_AUTH_USER_STATUS";
export const SEARCH_CLINIC_LIST = "SEARCH_CLINIC_LIST";
export const CLEAR_CLINIC = "CLEAR_CLINIC";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA"
export const GET_STATE_DATA = 'GET_STATE_DATA';
export const RESEND_INVITATION_AUTH_USER ="RESEND_INVITATION_AUTH_USER";
export const DELETE_CLINICE = "DELETE_CLINICE";
export const GET_MINUTE_DATA = "GET_MINUTE_DATA";
export const UPDATE_CLINIC_MINUTE_PLAN = "UPDATE_CLINIC_MINUTE_PLAN";
export const CANCEL_CLINIC_MINUTE_PLAN = "CANCEL_CLINIC_MINUTE_PLAN";

//admin//physician
export const ADD_PHYSICIAN = "ADD_PHYSICIAN";
export const UPDATE_STATUS_PHYSICIAN = "UPDATE_STATUS_PHYSICIAN";
export const DELETE_PHYSICIAN = "DELETE_PHYSICIAN";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// default
export const ADD_PHYSICIAN_LIST = "ADD_PHYSICIAN_LIST";
export const GET_PHYSICIAN_LIST = "GET_PHYSICIAN_LIST";
export const EDIT_PHYSICIAN_LIST = "EDIT_PHYSICIAN_LIST";
export const UPDATE_STATUS_PHYSICIAN_LIST = "UPDATE_STATUS_PHYSICIAN_LIST";
export const SEARCH_PHYSICIAN_LIST = "SEARCH_PHYSICIAN_LIST";
export const ADD_MINUTE_PHYSICIAN_LIST = "ADD_MINUTE_PHYSICIAN_LIST";
export const DELETE_PHYSICIAN_LIST ="DELETE_PHYSICIAN_LIST";
export const GET_PHYSICIAN_DETAILS = "GET_PHYSICIAN_DETAILS";
export const SEARCH_PHAYSICIAN_PATIENTS = "SEARCH_PHAYSICIAN_PATIENTS";
export const GET_PHYSICIAN_PATIENTS = "GET_PHYSICIAN_PATIENTS";
export const UPDATE_PHYSICIAN_LIST = "UPDATE_PHYSICIAN_LIST";

// clinic
export const UPDATE_CLINIC_PHYSICIAN ="UPDATE_CLINIC_PHYSICIAN";
export const UPLOAD_EMAIL_IMG = 'UPLOAD_EMAIL_IMG';
//invoice
export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
//website leads
export const GET_WEBSITE_LEADS_DATA = "GET_WEBSITE_LEADS_DATA";
export const SEARCH_WEBSITE_LEADS_DATA = "SEARCH_WEBSITE_LEADS_DATA";
export const GET_SETTING_DATA = "GET_SETTING_DATA";
export const UPDATE_MAINTENANCE_MODE = "UPDATE_MAINTENANCE_MODE";
export const UPDATE_APP_VERSION = "UPDATE_APP_VERSION";